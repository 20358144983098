
import { number_to_currency } from '@/lib/helpers'
import QuantityControl from '@/components/quantity_control'
import ComboItems from '@/components/combo_items'

export default {
  props: {
    item: { type: Object, default: { offer: {}, qty: 0 } }
  }
  components: { QuantityControl, ComboItems }
  computed: {
    is_marketplace: ->
      !@$store.getters['application/is_agency']

    formatted_unit_price: ->
      return '' unless @item.offer

      text = "#{@$store.getters['application/currency']} #{@item.offer.price}"
      if @item.offer.per_kg then "#{text} /#{@item.offer.per_kg_symbol}" else text

    unit_label: ->
      if @item.offer.per_kg then @item.offer.per_kg_symbol else ''

    bulk_pack_savings: ->
      return null unless @item.offer
      cost_at_base_price = @item.offer.base_price_in_cents * Number(@item.offer.sellable.pack)
      savings_in_cents = cost_at_base_price - @item.offer.price_in_cents

      return @number_to_currency(savings_in_cents * @item.qty) if savings_in_cents > 0
      null

  }
  methods: {
    remove_item: ->
      @$store.dispatch("cart/remove_item", @item.offer.id)

    number_to_currency: (cents) ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: cents })
  }
  watch: {
    'item.qty': (v) ->
      @$store.dispatch("cart/set_item_qty", { offer_id: @item.offer.id, qty: @item.qty})
  }
}
