
import PageNavbar from '@/components/page_navbar'
import Geolocator from '@/lib/geolocator'
import BasketItem from '@/components/basket_item'
import { number_to_currency } from '@/lib/helpers'

export default {
  components: { PageNavbar, BasketItem },

  data: -> {
    loading_location: false,
    confirm_cart_reset_prompt: false
  },

  computed: {
    items: ->
      @$store.state.cart.items
    ,
    sorted_items: ->
      @$store.state.cart.items.sort (a, b) -> if a.offer.out_of_stock_at then -1 else 1
    ,
    market_application: ->
      !@$store.getters['application/is_agency']
    ,
    formatted_total: ->
      if @$store.state.cart.proxy_order_id then @formatted_order_total else @formatted_cart_total
    ,
    formatted_cart_total: ->
      number_to_currency({ symbol: @$store.getters['application/currency'], cents: @$store.getters["cart/subtotal_in_cents"] })
    ,
    checkout_button_text: ->
      @market_application and not @$store.getters['suppliers/current'] and @$t('cart_page_compare') || @$t('cart_page_place_order')

    checkout_disabled: ->
      @loading_location ||
      @items.filter((item) => !item.offer.out_of_stock_at).length == 0
  },

  methods: {
    initiate_checkout: ->
      @$store.commit('checkout/setup', @$store.getters['cart/build_checkout'])
      @$store.dispatch('analytics/begin_checkout')
      @track_meta_event()
      @$router.push('/checkout/dispatch')
        .catch (error) => console.error 'unauthenticated', error

    track_meta_event: ->
      items = ({id: item.offer.sellable.barcode, qty: item.qty} for item in @items)
      barcodes = (item.id for item in items)
      payload = {
        content_ids: barcodes,
        contents: items,
        currency: @$store.getters['application/currency_iso'],
        num_items: items.length,
        value: @$store.getters['cart/total']
      }
      fbq 'track', 'InitiateCheckout', payload

    checkout: ->
      if @market_application
        selected_supplier = @$store.getters['suppliers/current']
        if selected_supplier
          @$store.commit('checkout/setup', { supplier: selected_supplier, items: @items })
          @$store.dispatch('checkout/fetch_client_config')
          .then (config) =>
            @$router.push('/checkout/dispatch')
        else
          @fetch_cart_comparison()
      else
        @initiate_checkout()

    reset_cart: (confirmed) ->
      @$store.dispatch("cart/clear") if confirmed
      @confirm_cart_reset_prompt = false

    fetch_cart_comparison: ->
      @loading_location = true
      @get_user_location()

    get_user_location: ->
      new Geolocator(@set_cart_location, @geolocate_error).locate()

    set_cart_location: (location) ->
      @$store.commit("cart/set_location", location)
      @$router.push('/basket/compare')
        .catch (error) => console.error 'unauthenticated', error

    geolocate_error: (error) ->
      console.error('geolocate_error', error)
      @set_cart_location(null)
  }
}
